import React from 'react';

import './Game.css'




export default function Game() {



  return (
    <>

      <div className='background'>




        <div className="header2">
 xWizard Game
        </div>

        <div className='sign-in'>
          <text>Sign in to select a xWizard for battle</text>
          <a className='signin-button' >Sign In</a>
        </div>

        <div className='nfts-container'>
        <div id ="nftPlugin" issuer="rWizQRjdHoBLuEjUoHdcFharvgsPKXd7q" taxon="	1958998591" display="30" cardWidth="350" more="no"></div>
<script src="https://nftoken.id/plugins/floor.js?v0.22"></script>

          <p className='nfts-text'>
            test
          </p>
        </div>

      </div>




    </>
  );
}