import React from 'react';
import '../../App.css';
import TokenButton  from './TokenButton';

export default function Tokens() {
  return (
    <>
     <div className='tokens'>
      
     <TokenButton/>
    
     </div>
     
    </>
  );
}