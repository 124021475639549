import {React,useState, useEffect} from 'react';
import './Pages.css'
import {Link} from 'react-router-dom'




const NftButton = () => {
  
  // websocket -----------------------------------------------------------
  const socket = new WebSocket('wss://xwizard.app:8080')
  //const socket = new WebSocket('ws://localhost:8080')

  // Incomeing socket messages ---------------------------------------------------
  useEffect(() => {
    socket.onmessage = ({ data }) => {
    console.log('websocket connected')
    let socketInfo =JSON.parse(data)
    
      if(socketInfo.xumm){
      setSubscription(socketInfo)
      console.log(subscription)
      }

      if(socketInfo.buyOffer){
        setBuyOffer(socketInfo)
        console.log('recived buy offer')
        }

      if(socketInfo.burnResult){
        setResult(socketInfo)
        console.log(result)
      } 
      
      if(socketInfo.offerResult){
        setResult(socketInfo)
        console.log(result)
      }

      if(socketInfo.NFTimage){
        setNFTimage(socketInfo)
        console.log(NFTimage)
      }


    }
  },[])




  // states -----------------------------------------------------------
  const [subscription, setSubscription] =useState([{}])
  const [buyOffer, setBuyOffer] =useState([{}])
  const [result, setResult] =useState([{}])
  const [burnClick, setBurnClick] = useState(false);
  const [NFTimage, setNFTimage] =useState([{}])

  // button handlers -----------------------------------------------------------
  const handleBurnClick = () => setBurnClick(!burnClick);

  // refresh page -----------------------------------------------------------
  const refreshPage = ()=>{
  window.location.reload();
  }

  // handle token burn button -----------------------------------------------------------
  let burnRequest =() =>{
   
   //call web server
    let nftCall = {
    "command": "nftCall",
    } 

   //send message
   if (burnClick == false){
   socket.send(JSON.stringify(nftCall))
   handleBurnClick()
   }  

   if (burnClick == true && result.offerResult){
    refreshPage()
    }  


  }

  // handle nft exchange buttons -----------------------------------------------------------
  let openWin = () => {
     console.log('ran open window')

     if(subscription.xumm){
    result.burnResult || result.offerResult ? console.log('burn request already open') : window.open(subscription.xumm.next.always) && console.log('opened burn request')
     }

  }

  let openWin2 = () => {
    console.log('ran open window')

    if(buyOffer.buyOffer){
     result.offerResult ? console.log('buy offer already open') : window.open(buyOffer.buyOffer.next.always) && console.log('opened buy offer') 
     }
 }


   // handle nft exchange buttons -----------------------------------------------------------



// HTML ------------------------------------------------------------------------------
return (
   <div className='container'>
  
  
   
      <p className='text'>Purchase a xWizard NFT.</p>
      <p className='text'>10 XRP</p>
      <a className='btn1' onClick={burnRequest}>1. Start</a>
      {subscription.xumm && openWin()}
      {subscription.xumm && <button className='btn2' onClick={()=>openWin()} >2. Back Up Mint Request</button>}
      {buyOffer.buyOffer && <button className='btn2' onClick={()=>openWin2()} >3. Back Up NFT Offer</button>}
      
      {result.burnResult && <p className='text'>{result.burnResult} Creating a NFT offer!</p>}
      {NFTimage.NFTimage && <img className='image-element' src={NFTimage.NFTimage} />}
      {result.offerResult && <p className='text-green' >{result.offerResult}</p>}

      
   </div>
   
   
 );
}

export default NftButton