import React from 'react';
import '../../App.css';
import './Pages.css'


export default function Home() {
  return (
    <>
      <div className='home'>
       
        <div className='text'>
        <h1 className='header'>Welcome To the xWizard App!</h1>
        <p>We designed this app to streamline the xWizard project. You can Mint xWizard NFTs from the “mint” page. If your looking for project information about the xWizard NFT project, visit our “about” page. Thanks for visiting!
        </p>
        
        
        </div>
      </div>
      
    </>
  );
}