import {React,useState, useEffect} from 'react';
import './Pages.css'
import {Link} from 'react-router-dom'

const TokenButton = () => {

  const socket = new WebSocket('wss://xwizard.app:8080')

// handle websocket messages -----------------------------------------------------------
  const [subscription, setSubscription] =useState([{}])
  const [result, setResult] =useState([{}])

  useEffect(() => {
    socket.onmessage = ({ data }) => {
    console.log('websocket connected')
    let socketInfo =JSON.parse(data)
    
      if(socketInfo.xumm){
      setSubscription(socketInfo)
      console.log(subscription)
      }

      if(socketInfo.result){
        setResult(socketInfo)
        console.log(result)
        }
    }
  },[subscription])



  const refreshPage = ()=>{
    window.location.reload();
 }


// handle xumm sign -----------------------------------------------------------
    let openWin = () => {

      result.result ? ('already open') : window.open(subscription.xumm.next.always)
     console.log('opened xumm')
     }
     
// handle user input -----------------------------------------------------------
let tokenInfo =() =>{

  // define token call to server
  let tokenCall = {
    "command": "tokenCall",
     "amount": document.getElementById( "xwizard" ).value
  } 

  // take token input and make it a number
  let amount = Number(document.getElementById( "xwizard" ).value)
    
  // check for int and whole number
  if(amount % 1 === 0){
  Number.isFinite(amount) ?  socket.send(JSON.stringify(tokenCall)) : console.log('fail')
  
  } else {
    console.log('fail')
  }

}

console.log('Token Button')


const handleAlert = ()=> {
if(result.result == "tesSUCCESS"){

  alert(`${result.result} tokens are on the way to your wallet! Here is your on ledger receipt # ${result.onChain}.`)
}else{
  alert(`${result.result} error! There was a issue with your transaction contact devs on Cbot Labs Inc. discord or twitter for help.
  Here is your receipt # ${result.onChain} please make a note of this.`)
}
}



return (
   <div>
   
  <form>
      <input type="text" id="xwizard" name="xwizard"/>
      <input className='btn1' value="Get Tokens" type='button' onClick={tokenInfo}></input>
  </form>
  <a href='https://xrpl.services/?issuer=rUifimbEkHzyQYM88iJzhUnFSYPvAkiFtZ&currency=5877697A61726400000000000000000000000000&limit=1895.8713734494083' className='btn2'>Trustline</a>
  <a href='https://sologenic.org/trade?market=5877697A61726400000000000000000000000000%2BrUifimbEkHzyQYM88iJzhUnFSYPvAkiFtZ%2FXRP&network=mainnet' className='btn2'>Trade</a>
      <p className='text'>Enter the token quantity and click the button to switch XRP for xWizard tokens at the presale price. Make sure you have an xWizard trustline before proceeding!</p>
      {result.result && refreshPage()}
      {result.result && handleAlert()}
      {subscription.xumm && openWin()}
     
     
      
   </div>
   
   
 );
}

export default TokenButton