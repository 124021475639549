import React from 'react';
import '../../App.css';
import './Pages.css'




export default function About() {
  return (
    <>
      <div className='about'>
      <div>
        <h1 className='header'>Here To Learn More?</h1>
        <p className='text'> xWizard is an exclusive NFT project with a supply of 2000. The xWizard NFTs are mintable in this app for 10 XRP. Each xWizard is randomly generated from 6 properties with varying rarities. Cbot Labs may release an xWizard game. NFT game utility will be revealed if a game is created. Click the link below to learn more!
         
        </p>
        <a href='https://cbotlabs.xyz/' className='btn1'>Cbot Labs Inc</a>
        
        </div>
        
      </div>
    </>
  );
}