
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import {BrowserRouter, Routes,Route} from 'react-router-dom';
import About from './components/pages/About';
import Home from './components/pages/Home';
import Mint from './components/pages/Mint';
import Game from './components/game/Game'
import Tokens from './components/pages/Tokens'
function App() {

  return (
   <BrowserRouter>
     <Navbar />
     <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/mint" element={<Mint/>}/>
      <Route path="/game" element={<Game/>}/>
      <Route path="/tokens" element={<Tokens/>}/>
    </Routes>     
   </BrowserRouter>   
   
  );
}

export default App;
