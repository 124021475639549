import React from 'react';
import '../../App.css';
import NftButton from './NftButton';

export default function Mint() {
  return (
    <>
      <div className='mint'>

      <NftButton/>

      </div>
    </>
  );
}
