import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';


function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

 
  //const socket = new WebSocket('ws://localhost:8080');
  //console.log(socket)
  
  return (
    <>
      <nav className='navbar'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          xWizard
          <i className ="fa-solid fa-hat-wizard"></i>
        </Link>

        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>

        <ul className={click ? 'nav-menu active' : 'nav-menu'}>

          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
         
         

          <li className='nav-item'>
            <Link
              to='/About'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              About
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to='/mint'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Mint NFT
            </Link>
          </li>

         

       


        </ul>
        
       
      </nav>
    </>
  );
}

export default Navbar;